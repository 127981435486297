<template>
    <div class="bulkImportPage">
      <el-breadcrumb separator="/" style="margin:10px 0">
        <el-breadcrumb-item :to="{ path: '/platform/carManage/list' }">车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>导入车辆</el-breadcrumb-item>
      </el-breadcrumb>
        <div class="facilityBox">
          <div class="head">
            <el-button size="medium" @click="$router.back()" icon="el-icon-arrow-left">返回</el-button>
            <el-button type="primary" size="medium" @click="save" icon="el-icon-check">提交资料</el-button>
            <el-button type="primary" icon="el-icon-wallet" size="medium" @click="flag.showDriverInfo= !flag.showDriverInfo">导入车辆信息</el-button>
            <el-button type="primary" icon="el-icon-picture-outline" size="medium" @click="flag.showDriverPhoto= !flag.showDriverPhoto">导入车辆证照</el-button>
          </div>
          <el-table :data="tableData" v-loading="loading" :header-cell-style="{background:'#f0f0f0',color:'#666'}">
              <el-table-column fixed align="center" type="index" label="序号" width="50"></el-table-column>
              <el-table-column align="center" prop="VehicleCarNumber" label="车牌号" fixed width="100"></el-table-column>
              <!-- <el-table-column align="center" prop="CarNumberType" label="牌照类型"></el-table-column> -->
              <el-table-column align="center" prop="CarNumberColor" label="车牌颜色"></el-table-column>
              <el-table-column align="center" prop="VehicleType" label="车辆类型"></el-table-column>
              <el-table-column align="center" prop="VehicleLength" label="车身长度"></el-table-column>
              <el-table-column align="center" prop="VehicleWidth" label="车身宽度"></el-table-column>
              <el-table-column align="center" prop="VehicleLoad" label="载重（吨）" width="100"></el-table-column>
              <el-table-column align="center" prop="VehicleRTP" label="道路运输证号" width="190"></el-table-column>
              <el-table-column align="center" prop="VehicleRTPDate" label="运输证有效期至" width="190"></el-table-column>
              <el-table-column align="center" prop="VehicleLicenseArchives" label="行驶证编号" width="190"></el-table-column>
              <el-table-column align="center" prop="VehicleLicenseValidDate" label="行驶证有效结束时间" width="190"></el-table-column>
              <el-table-column align="center" prop="VehicleLicenseFrontPageURL" label="车辆行驶证正页" width="200">
                  <template slot-scope="scope">
                    <img :src="scope.row.VehicleLicenseFrontPageURL" @click="previewImg(scope.row.VehicleLicenseFrontPageURL)">
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="VehicleLicenseSubPageOnURL" label="车辆行驶证副页(正面)" width="200">
                  <template slot-scope="scope">
                    <img :src="scope.row.VehicleLicenseSubPageOnURL" @click="previewImg(scope.row.VehicleLicenseSubPageOnURL)">
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="VehicleLicenseSubPageBackURL" label="车辆行驶证副页(反面)" width="200">
                  <template slot-scope="scope">
                    <img :src="scope.row.VehicleLicenseSubPageBackURL" @click="previewImg(scope.row.VehicleLicenseSubPageBackURL)">
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="VehicleRTPUTL" label="道路运输经营许可证" width="200">
                  <template slot-scope="scope">
                    <img :src="scope.row.VehicleRTPUTL" @click="previewImg(scope.row.VehicleRTPUTL)">
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="ErrorMsg" label="导入错误信息" width="200"></el-table-column>
              <el-table-column fixed="right" align="center" label="操作" width="100">
                  <template slot-scope="scope">
                      <el-button @click="editDriverInfo(scope.row)" icon="el-icon-edit" type="primary" size="small" title="编辑">编辑</el-button>
                  </template>
              </el-table-column>
          </el-table> 
        </div>
        <!-- 导入车辆信息dialog -->
        <el-dialog class="driverInfo" :visible.sync="flag.showDriverInfo" width="540px" >
          <span slot="title">导入车辆信息</span>
            <div class="body">
                <el-button type="primary" size="medium" @click="VehicleExcel">上传Excel表格数据</el-button>
                <el-alert style="margin: 10px"
                  type="warning"
                  :closable="false">
                  <span slot="title">
                    <span>1.请按照excel字段规则填写； 2.单次导入不超过1000条数据；</span>
                  </span>
                </el-alert>
                <div class="download" @click="download">点击下载(车辆批量导入Excel模板)</div>
                <input ref="driverInfo" type="file" style="display:none" @change="importDriverInfo">
            </div>
        </el-dialog>
        <!-- 导入车辆证件照dialog -->
        <el-dialog class="driverPhoto" :visible.sync="flag.showDriverPhoto" width="800px" :close-on-click-modal="false" :close-on-press-escape="false">
          <span slot="title">导入司机证件照</span>
          <div class="body">
            <!-- 上传图片区域 -->
            <div class="uploadArea">
              <div class="bulkImportList">
                <div class="item" v-for="(item, index) in bulkImportList" :key="index">
                  <img :src="item.url">
                  <span>{{item.name}}</span>
                  <i class="el-icon-delete" @click="delListItem(index)"></i>
                </div>
              </div>
              <div class="upload" @click="$refs.driverPhoto.click()">选择文件</div>
            </div>
            <el-alert style="margin: 10px"
              type="warning"
              :closable="false">
              <span slot="title">
                <span>1. 限制一次性最多上传2000张;</span> <br/>
                <span>2. 支持图片格式：jpeg/jpg/png;</span> <br/>
                <span>3. 司机证件照命名规则如下;</span> <br/>
                <span>行驶证正页：行驶证编号-1.jpg、 行驶证副页(正面)：行驶证编号-2.jpg、行驶证副页(反面)：行驶证编号-2.jpg、 运输经营许可证：行驶证编号-4.jpg;</span> <br/>
              </span>
            </el-alert>
            <input ref="driverPhoto" type="file" style="display:none" @change="importDriverPhoto">
            <!-- 上传 -->
            
          </div>
          <span slot="footer">
            <el-button type="primary" size="medium" @click="confirmImportDriverPhoto">上传</el-button>
          </span>
        </el-dialog>
         <!-- 编辑司机图片dialog -->
        <el-dialog class="editDriverForm" :visible.sync="flag.editDriverPhoto" title="图片修改" width="70%" center :close-on-click-modal="false" :close-on-press-escape="false">
          <el-form :model="editDriverForm" :rules="editDriverRule" ref="editDriverForm" label-width="170px">
            <div class="content">
              <el-form-item label="机动车驾驶证正页" prop="driverFace.picUrl">
                <UploadImg :loading="editDriverForm.driverFace.loading" :uploaded="editDriverForm.driverFace.uploaded" :picUrl="editDriverForm.driverFace.picUrl" @update:file="updateFile" @change="uploadDriverFace(editDriverForm.driverFace)" @deleteImg="deleteImg(editDriverForm.driverFace)"></UploadImg>
              </el-form-item>
              <el-form-item label="机动车驾驶证副页(正页)" prop="driverBack.picUrl">
                <UploadImg :loading="editDriverForm.driverBack.loading" :uploaded="editDriverForm.driverBack.uploaded" :picUrl="editDriverForm.driverBack.picUrl" @update:file="updateFile" @change="uploadDriverBack(editDriverForm.driverBack)" @deleteImg="deleteImg(editDriverForm.driverBack)"></UploadImg>
              </el-form-item>
              <el-form-item label="机动车驾驶证副页(反页)" prop="driveron.picUrl">
                <UploadImg :loading="editDriverForm.driveron.loading" :uploaded="editDriverForm.driveron.uploaded" :picUrl="editDriverForm.driveron.picUrl" @update:file="updateFile" @change="uploadDriverOn(editDriverForm.driveron)" @deleteImg="deleteImg(editDriverForm.driveron)"></UploadImg>
              </el-form-item>
              <el-form-item label="道路运输从业资格证" prop="conveyLicenceUrl.picUrl">
                <UploadImg :loading="editDriverForm.conveyLicenceUrl.loading" :uploaded="editDriverForm.conveyLicenceUrl.uploaded" :picUrl="editDriverForm.conveyLicenceUrl.picUrl" @update:file="updateFile" @change="uploadConveyLicence(editDriverForm.conveyLicenceUrl)" @deleteImg="deleteImg(editDriverForm.conveyLicenceUrl)"></UploadImg>
              </el-form-item>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="flag.editDriverPhoto = false">取 消</el-button>
            <el-button type="primary" @click="confirmEdit('editDriverForm')">保存</el-button>
          </span>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="flag.showImg">
          <img width="100%" :src="currentImgUrl">
        </el-dialog>
    </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { addVehicleInfo } from '@/api/platform/carManage/index'
import { VehicleExcel } from '@/api/platform/carManage/bulkImport'
import { uploadVehicleFace, uploadVehicleBack, uploadVehiclePageOn,uploadVehicleRTP } from '@/api/platform/carManage/verify'
export default {
    data() {
        return{
          loading: false, // 表格加载
          tableData: [], //表格数据
          flag: {
            showDriverInfo: false, //是否显示导入司机信息dialog
            showDriverPhoto: false, //是否显示导入司机照片dialog
            editDriverPhoto: false, //是否显示编辑司机照片dialog
            showImg: false,
          },
          //正在编辑的表格item的图片url
          currentEditItem: {
            DriverLicenceFace: '',
            DriverLicenceBack: '',
            DriverLicenceOn:'',
            conveyLicenceUrl: ''
          }, 
          bulkImportList: [], //批量导入的数据
          editDriverForm: {
            driveron:{
              loading: false,
              uploaded: false,
              picUrl: '',
              file: ''
            },
            driverFace: {
              loading: false,
              uploaded: false,
              picUrl: '',
              file: ''
            },
            driverBack: {
              loading: false,
              uploaded: false,
              picUrl: '',
              file: ''
            },
            conveyLicenceUrl: {
              loading: false,
              uploaded: false,
              picUrl: '',
              file: ''
            },
          },
          editDriverRule: {
            'driverFace.url': [{ required: true, message: '请上传驾驶证正面', trigger: 'change' }],
            'driverBack.url': [{ required: true, message: '请上传驾驶证反面', trigger: 'change' }],
          },
          currentImgUrl: '', //正在预览的图片
        }
    },

    methods:{
      //保存
      save() {
        let params = {
          baseInfo: [],
          confirmInfo: [],
          type:1
        }
        for(let i in this.tableData){
          let item = this.tableData[i]
          if(item.VehicleLicenseFrontPageURL == null){
            this.$message.error(`请上传第${Number(i) + 1}行中的车辆行驶证正页`)
            return
          }
          if(item.VehicleLicenseSubPageOnURL == null){
            this.$message.error(`请上传第${Number(i) + 1}行中的车辆行驶证副页(正面)`)
            return
          }
          if(item.VehicleLicenseSubPageBackURL == null){
            this.$message.error(`请上传第${Number(i) + 1}行中的车辆行驶证副页(反面)`)
            return
          }
          if(item.VehicleRTPUTL == null){
            this.$message.error(`请上传第${Number(i) + 1}行中的道路运输经营许可证`)
            return
          }
          params.baseInfo.push({
            vehicleID: '',
            VehicleCarNumber: item.VehicleCarNumber,
            CarNumberColor: item.CarNumberColorCode,
            vehicleType: item.VehicleTypeCode,
            VehicleRTP: item.VehicleRTP,
            VehicleRTPDate:item.VehicleRTPDate,
            VehicleLicenseArchives:item.VehicleLicenseArchives,
            VehicleLicenseValidDate:item.VehicleLicenseValidDate,
            VehicleLength:item.VehicleLength,
            VehicleWidth:item.VehicleWidth,
            VehicleLoad:item.VehicleLoad
          })
          params.confirmInfo.push({
            UserId: 0,
            VehicleRTPUTL:item.VehicleRTPUTL,
            VehicleLicenseFrontPageURL: item.VehicleLicenseFrontPageURL, //机动车驾驶证（正页)
            VehicleLicenseSubPageOnURL: item.VehicleLicenseSubPageOnURL, //机动车驾驶证（副页)
            VehicleLicenseSubPageBackURL: item.VehicleLicenseSubPageBackURL, //道路运输从业资格证
          })
        }
        addVehicleInfo(params).then(res => {
          this.$router.go(-1)
        })
      },
      //设置当前组件正在操作的文件
      updateFile(file) {
        this.currentFile = file;
      },
      //上传驾驶证正面
      uploadDriverFace(field) {
        field.loading = true
        let formData = new FormData()
        formData.append('file', this.currentFile)
        uploadVehicleFace(formData).then(res => {
          field.loading = false
          field.uploaded = true
          field.picUrl = res.data.vehicles.vehicleFaceUrl
          this.editDriverForm.driverFace.picUrl = res.data.vehicles.vehicleFaceUrl
        }).catch(() => { })
          .finally(() => {
            field.loading = true
            field.uploaded = true
          })
      },
      //上传驾驶证反面
      uploadDriverBack(field) {
        field.loading = true;
        let formData = new FormData()
        formData.append('file', this.currentFile)
        uploadVehicleBack(formData).then(res => {
          field.loading = false
          field.uploaded = true
          field.picUrl = res.data.vehicles.vehicleBackUrl
          this.editDriverForm.driverBack.picUrl = res.data.vehicles.vehicleBackUrl
        }).catch(() => { })
          .finally(() => {
            field.loading = true
            field.uploaded = true
          })
      },
      //上传道路运输从业资格证
      uploadDriverOn(field) {
        field.loading = true
        let formData = new FormData()
        formData.append('file', this.currentFile)
        uploadVehiclePageOn(formData).then(res => {
          field.loading = false
          field.uploaded = true
          field.picUrl = res.data.vehicles
          this.editDriverForm.driveron.picUrl = res.data.vehicles
        }).catch(() => { })
          .finally(() => {
            field.loading = true
            field.uploaded = true
          })
      },
      //上传道路运输从业资格证
      uploadConveyLicence(field) {
        field.loading = true
        let formData = new FormData()
        formData.append('file', this.currentFile)
        uploadVehicleRTP(formData).then(res => {
          field.loading = false
          field.uploaded = true
          field.picUrl = res.data.vehicles
          this.editDriverForm.conveyLicenceUrl.picUrl = res.data.vehicles
        }).catch(() => { })
          .finally(() => {
            field.loading = true
            field.uploaded = true
          })
      },
      //导入司机证照
      importDriverPhoto(e) {
        let file = e.srcElement.files[0] || e.target.files[0]
        //获取文件名判断是否符合命名规范
        let name = e.srcElement.files[0].name || e.target.files[0].name
        //获取图片的类型 1.行驶证正面 2.行驶证反面 3.道路运输经营许可证
        let type = name.substring(name.indexOf('.') - 1, name.indexOf('.'))
        let reg = /^[1-8]\d{11}[-][1,2,3,4,5]$/;
        if (reg.test(name.substr(0, name.indexOf('.'))) == false) {
          this.$message.error('图片命名不符合规范')
        } else {
          let formData = new FormData()
          formData.append('file', file)
          if (type == 1) {
            uploadVehicleFace(formData).then(res => {
              this.currentEditItem.DriverLicenceFace = res.vehicles.vehicleFaceUrl
              this.bulkImportList.push({
                url: res.vehicles.vehicleFaceUrl,
                name: name
              })
            })
          } else if (type == 2) {
            uploadVehicleBack(formData).then(res => {
              this.currentEditItem.DriverLicenceBack = res.vehicles.vehicleBackUrl
              this.bulkImportList.push({
                url: res.vehicles.vehicleBackUrl,
                name: name
              })
            })
          } else if (type == 3) {
            uploadVehiclePageOn(formData).then(res => {
              this.currentEditItem.DriverLicenceOn = res.vehicles
              this.bulkImportList.push({
                url: res.vehicles,
                name: name
              })
            })
          }else if (type == 4) {
            uploadVehicleRTP(formData).then(res => {
              this.currentEditItem.conveyLicenceUrl = res.vehicles
              this.bulkImportList.push({
                url: res.vehicles,
                name: name
              })
            })
          }
        }
        //   const file = e.srcElement.files[0] || e.target.files[0]
      },
      //把导入的司机证件照插入到表格中
      confirmImportDriverPhoto() {
        this.bulkImportList.forEach(item => {
          let phone = item.name.substring(0, 12)
          let type = item.name.substring(13, 14)
          this.tableData.forEach(el => {
            if (phone == el.VehicleLicenseArchives) {
              if (type == 1) {
                el.VehicleLicenseFrontPageURL = item.url
              } else if (type == 2) {
                el.VehicleLicenseSubPageOnURL = item.url
              } else if (type == 3) {
                el.VehicleLicenseSubPageBackURL = item.url
              }else if (type == 4) {
                el.VehicleRTPUTL = item.url
              }
            }
          })
        })
        this.flag.showDriverPhoto = false
      },
      //上传Excel
      VehicleExcel() {
        this.$refs.driverInfo.click()
        this.$refs.driverInfo.value = ''
      },
      //导入车辆信息
      importDriverInfo(e) {
        this.flag.showDriverInfo = true
        this.loading = true
        const file = e.srcElement.files[0] || e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        VehicleExcel(formData).then(res => {
          res.data.forEach((item, index) => {
            item.index = index
          })
          this.loading = false
          this.tableData = res.data
          this.flag.showDriverInfo = false
          //解决连续上传同一个文件不触发change事件
          this.$refs.driverInfo.value = ''
        })
      },
      //表格item编辑保存
      confirmEdit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //把保存的值放到表格里面去
            this.tableData[this.currentRow].VehicleLicenseFrontPageURL= this.editDriverForm.driverFace.picUrl
            this.tableData[this.currentRow].VehicleLicenseSubPageOnURL = this.editDriverForm.driverBack.picUrl
            this.tableData[this.currentRow].VehicleLicenseSubPageBackURL = this.editDriverForm.driveron.picUrl
            this.tableData[this.currentRow].VehicleRTPUTL = this.editDriverForm.conveyLicenceUrl.picUrl
            this.flag.editDriverPhoto = false
          }
        })
      },
      //下载Excel模板
      download() {
        window.open('https://res.gts56.com/fzkj/bsc/Driver/%E5%8F%B8%E6%9C%BA%E8%BD%A6%E8%BE%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xls')
      },
      // 图片预览
      previewImg(url) {
        this.currentImgUrl = url
        this.flag.showImg = !this.flag.showImg
      },
      //编辑司机信息
      editDriverInfo(item) {
        this.currentRow = item.index
        this.flag.editDriverPhoto = !this.flag.editDriverPhoto
      },
      //删除图片
      deleteImg(field) {
        field.uploaded = false
        field.loading = false
        field.picUrl = ''
      },
      //删除批量导入里得某一个
      delListItem(index) {
        this.$confirm('是否删除该照片?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.bulkImportList.splice(index, 1)
        }).catch(() => {
        })
      }
    },
    watch: {
    'flag.editDriverPhoto'(val) {
        if (!val) {
          //每次关闭编辑的dialog时清空里边的数据
          this.editDriverForm.driveron.picUrl = ''
          this.editDriverForm.driverFace.picUrl = ''
          this.editDriverForm.driverBack.picUrl = ''
          this.editDriverForm.conveyLicenceUrl.picUrl = ''
          //清空图片上传组件的状态
          this.editDriverForm.driveron.loading = false
          this.editDriverForm.driveron.uploaded = false
          this.editDriverForm.driverFace.loading = false
          this.editDriverForm.driverFace.uploaded = false
          this.editDriverForm.driverBack.loading = false
          this.editDriverForm.driverBack.uploaded = false
          this.editDriverForm.conveyLicenceUrl.loading = false
          this.editDriverForm.conveyLicenceUrl.uploaded = false
        } else {
          //每次打开的时候需要填充编辑dialog的数据
          this.editDriverForm.driveron.picUrl = this.tableData[this.currentRow].VehicleLicenseSubPageBackURL
          this.editDriverForm.driverFace.picUrl = this.tableData[this.currentRow].VehicleLicenseFrontPageURL
          this.editDriverForm.driverBack.picUrl = this.tableData[this.currentRow].VehicleLicenseSubPageOnURL
          this.editDriverForm.conveyLicenceUrl.picUrl = this.tableData[this.currentRow].VehicleRTPUTL
        }
      }
    },
    components: {
      UploadImg
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/variable.scss';
.bulkImportPage {
  .head {
    padding-bottom: 10px;
    border-bottom: 1px solid $borderGray;
    margin-bottom: 10px;
  }
  .funcs {
    padding: 0 0 10px 0;
    margin-bottom: 10px;
  }
  .el-table {
    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .driverInfo {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .el-alert{
      color: #f11010;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .hint {
        margin: 20px 0;
        font-size: 13px;
      }
      .download {
        color: $primary;
        cursor: pointer;
      }
    }
  }
  .driverPhoto {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .el-alert{
      color: #f11010;
    }
    .body {
      .uploadArea {
        .upload {
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          margin-top: 10px;
          border-radius: 10px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          cursor: pointer;
        }
        .bulkImportList {
          max-height: 400px;
          overflow-y: scroll;
          display: flex;
          flex-wrap: wrap;
          .item {
            position: relative;
            margin: 0 10px 10px 0;
            display: flex;
            flex-direction: column;
            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;
            }
            span {
              padding-top: 5px;
              font-size: 12px;
              color: $textGray2;
            }
            i {
              position: absolute;
              top: 0px;
              right: 0px;
              color: rgb(219, 9, 9);
              cursor: pointer;
              padding: 10px;
            }
          }
        }
      }
      .hint {
        margin: 20px 0;
        font-size: 13px;
      }
    }
  }
  .editDriverForm {
    .content {
      display: flex;
      flex-wrap: wrap;
      .el-upload__tip {
        color: $textGray2;
      }
    }
  }
}
</style>