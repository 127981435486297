import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'
//上传Excel
export const uploadExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//批量认证司机信息
export const saveDriverInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDriverInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量导入司机
export const DriverExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量导入车辆
export const VehicleExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}