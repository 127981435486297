import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//新增车辆认证
export const saveVehicleInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDriverInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取认证信息
export const getVehicleInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Vehicle/VehicleDeatils?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传行驶证正页
export const uploadVehicleFace = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/VehicleFace`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传行驶证副页正面照
export const uploadVehicleBack = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/VehiclePageOn`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传行驶证副页反面照
export const uploadVehiclePageOn = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/VehiclePageBack`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传车辆道路运输证照片
export const uploadVehicleRTP = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/VehicleRTP`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}