import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取车辆信息
export const getVehicleInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/GetVehicleList`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}
// 获取车辆信息
export const VehicleDeatils = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Vehicle/VehicleDeatils?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
//新增车辆信息
export const addVehicleInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/AddVehicle`, params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}


//删除车辆信息
export const delVehicleInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/DelVehicle?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//导出Excel
export const exportExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/ExportExcel?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//修改车辆信息
export const UpdateVehicleInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/EditVehicle`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}
